import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col } from "antd"

const IntegrationsLogos = ({ white }) => {
  const data = useStaticQuery(graphql`
    query {
      quickbooksImage: file(relativePath: { eq: "logo-quickbooks.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      sellsyImage: file(relativePath: { eq: "logo-sellsy.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      azopioImage: file(relativePath: { eq: "logo-azopio.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      gutter={{ xs: 20, sm: 20, lg: 40 }}
      className="rc-startup-logos"
      style={{ width: '100%', marginTop: '-20px' }}
    >
      <Col xs={8} lg={4} align="center" style={{ marginTop: '40px' }}>
        <GatsbyImage image={data.quickbooksImage.childImageSharp.gatsbyImageData} alt="Quickbooks logo" />
      </Col>
      <Col xs={8} lg={4} align="center" style={{ marginTop: '40px' }}>
        <GatsbyImage image={data.sellsyImage.childImageSharp.gatsbyImageData} className='mb-4' alt="Sellsy logo" />
      </Col>
      <Col xs={8} lg={4} align="center" style={{ marginTop: '40px' }}>
        <GatsbyImage image={data.azopioImage.childImageSharp.gatsbyImageData} alt="Azopio logo" />
      </Col>
    </Row>
  )
}

export default IntegrationsLogos