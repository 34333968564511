import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Row, Col, Typography } from "antd"

import Layout from "../../components/layout"
import Head from "../../components/head"
import BetaTestersLogos from "../../components/beta-testers-logos"
import IntegrationsLogos from "../../components/integrations-logos"
import ComingTag from "../../components/coming-tag"
import FreeTrialCta from "../../components/free-trial-cta"
import CtaRow from "../../components/cta-row"

const { Title, Paragraph, Text } = Typography

const Pme = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      oneImage: file(relativePath: { eq: "suivi-tresorerie-image-5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      twoImage: file(relativePath: { eq: "multi-scenarios-image-3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      threeImage: file(relativePath: { eq: "anticiper-tresorerie-image-5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      fourImage: file(relativePath: { eq: "suivi-tresorerie-image-3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      pierreImage: file(relativePath: { eq: "pierre-cathelin.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      hubvisoryImage: file(relativePath: { eq: "hubvisory-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      arrowOneImage: file(relativePath: { eq: "arrow-down-1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowTwoImage: file(relativePath: { eq: "arrow-down-2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90)
        }
      }
    }
  `)

  const description = "Logiciel de gestion de trésorerie pour PME. Pilotez votre trésorerie en temps réel grâce à la connexion bancaire. Réalisez des prévisions fiables pour anticiper et prendre les meilleures décisions business."

  return (
    <Layout>
      <Head
        title="Outil de gestion de trésorerie pour PME"
        description={description}
        canonical="https://rocketchart.co/solutions/pme/"
      >
      </Head>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 13, offset: 0, order: 0 }}>
          <Title>Pourquoi des PME comme Hubvisory ont choisi RocketChart</Title>
          <Paragraph style={{ color: "black" }}>Gagnez du temps avec un suivi de trésorerie automatisé et construisez des prévisions fiables pour piloter votre PME. Réalisez différents scénarios prévisionnels pour prendre les bonnes décisions.</Paragraph>
          <CtaRow />
        </Col>
        <Col xs={{ span: 16, offset: 4, order: 0 }} lg={{ span: 11, offset: 0, order: 1 }} justify="center">
          <div style={{ marginBottom: "10px", marginRight: "10px" }}>
            <GatsbyImage image={data.hubvisoryImage.childImageSharp.gatsbyImageData} alt="logo Hubvisory" />
          </div>
          <Title level={4}>« RocketChart est un outil efficace pour suivre sa trésorerie. Il s'adapte bien à notre business model grâce aux catégories personnalisées et nous avons une bonne vue sur la performance de la société. Les scénarios nous permettent d'anticiper les impacts de nos choix pour prendre les bonnes décisions. »</Title>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <GatsbyImage image={data.pierreImage.childImageSharp.gatsbyImageData} alt="Pierre Cathelin - co-fondateur de Hubvisory" />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px" }}>
              <Text strong>Pierre Cathelin</Text>
              <Text id='fonctionnalites'>Co-fondateur - Hubvisory</Text>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="content-container" gutter={40} type="flex" justify="center" style={{ marginTop: "120px" }}>
        <Col span={24} align='center'>
          <Text strong style={{ color: '#6a737d' }}>Ils ont choisi RocketChart pour gérer leur trésorerie</Text>
        </Col>
        <BetaTestersLogos />
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "80px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowTwoImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 2" />
          </div>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.oneImage.childImageSharp.gatsbyImageData} alt="Automatisez la gestion de trésorerie de votre PME" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Automatisez la gestion de votre trésorerie et gagnez en clarté</Title>
          <Paragraph style={{ color: "black" }}>Synchronisez tous vos comptes bancaires et votre outils de facturation pour obtenir 100% de visibilité sur votre trésorerie. Oubliez les manipulations laborieuses sur votre fichier Excel de suivi et facilitez votre reporting.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={2}>Projetez votre trésorerie et identifiez vos besoins de financements</Title>
          <Paragraph style={{ color: "black" }}>Réalisez des prévisions fiables pour voir l’avenir sereinement et prendre les bonnes décisions. Gardez une longueur d’avance sur les difficultés financières et facilitez vos demandes de financements avec des projections à 1, 3, 6 ou 12 mois exportables.</Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.twoImage.childImageSharp.gatsbyImageData} alt="Projections de votre trésorerie" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.threeImage.childImageSharp.gatsbyImageData} alt="Comparaison facturé vs budget vs réalité" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Comparez l’écart entre votre Budget vs Facturé vs Réalisé</Title>
          <Paragraph style={{ color: "black" }}>Mesurez vos performances par rapport à vos budgets prévisionnels et comparez avec précision où vous en êtes de vos objectifs annuels. Ajustez facilement vos prévisions avec un outil dynamique pour conserver une vue claire de votre situation financière.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <ComingTag />
          <Title level={2}>Facilitez le rapprochement bancaire et le suivi de facturation</Title>
          <Paragraph style={{ color: "black" }}>Gagnez du temps et évitez les erreurs de saisie avec un pointage semi-automatisé de vos factures. Mesurez l’impact des retards de paiement sur votre trésorerie prévisionnelle pour relancer les clients au bon moment.</Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.fourImage.childImageSharp.gatsbyImageData} alt="Rapprochement bancaire facturation simplifié" />
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "80px", marginBottom: "80px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowOneImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down" />
          </div>
        </Col>
        <Col span={12} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Intégrez vos outils de facturation, ERP et logiciels de comptabilité</Title>
          <Paragraph style={{ color: "black" }}>Synchronisez en temps réel vos factures dans RocketChart pour générer automatiquement votre prévisionnel de trésorerie et automatiser le rapprochement banque / facture.</Paragraph>
        </Col>
        <Col span={24} align='center' style={{ marginTop: '20px' }}>
          <IntegrationsLogos />
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "0px", marginBottom: "60px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col xs={24} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Démarrez maintenant votre essai gratuit</Title>
          <FreeTrialCta />
        </Col>
      </Row>

    </Layout>
  )
}

export default Pme